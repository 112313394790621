// material-ui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

// ===========================|| WIDGET STATISTICS - WEATHER CARD ||=========================== //

const TimerCard = ({ countdown, currentQuestionIndex, totalQuestions }) => (
    <Grid container alignItems="center" justifyContent="center" spacing={0}>
        <Grid item xs={6} sm={6} lg={6} md={6} sx={{ p: 1, bgcolor: 'primary.dark', borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}>
            <Typography variant="h4" align="center" color={'primary.light'}>
                {countdown}s
            </Typography>
            <Typography variant="subtitle2" align="center" color={'primary.light'}>
                Time
            </Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6} md={6} sx={{ p: 1, bgcolor: 'primary.light', borderTopRightRadius: 7, borderBottomRightRadius: 7 }}>
            <Typography variant="h4" align="center" color={'primary'}>
                {currentQuestionIndex}/{totalQuestions}
            </Typography>
            <Typography variant="subtitle2" align="center" color={'primary'}>
                Questions
            </Typography>
        </Grid>
    </Grid>
);

TimerCard.propTypes = {
    countdown: PropTypes.number,
    currentQuestionIndex: PropTypes.number,
    totalQuestions: PropTypes.number
};

export default TimerCard;
