import { Button, Grid, Popover, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import PropTypes from 'prop-types';
import { KeyboardArrowDown } from '@mui/icons-material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { gridSpacing } from 'store/constant';

const TopicPopover = ({ topics, onSelected, reset }) => {
    const [selectedTopicId, setSelectedTopicId] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'topic-popover' : undefined;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const topicSelected = (topic) => {
        setSelectedTopicId(topic.id);
        onSelected(topic);
        handlePopoverClose();
    };

    const getTopicName = (id) => {
        if (typeof id === 'number') {
            let returnValue;
            topics?.forEach((topic) => {
                if (topic.id == id) {
                    returnValue = topic.name;
                }
            });
            return returnValue;
        }
    };

    useEffect(() => {
        reset && setSelectedTopicId('');
    }, [reset]);

    useEffect(() => {
        let preSelectedValues = JSON.parse(localStorage.getItem('preSelectedValuesInPracticePage'));
        if (preSelectedValues) {
            setSelectedTopicId(preSelectedValues.topic.id);
            onSelected(preSelectedValues.topic);
        }
    }, []);

    return (
        <SubCard contentSX={{ padding: '5px !important' }}>
            <Grid container>
                <Grid item sx={{ flexGrow: 1, minWidth: 0 }}>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handlePopoverOpen}
                        sx={{
                            width: '100%',
                            minWidth: '0',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography sx={{ fontWeight: '500' }} noWrap>
                            {typeof selectedTopicId == 'number' ? getTopicName(selectedTopicId) : 'Pick a Topic'}
                        </Typography>
                        <KeyboardArrowDown />
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        <Grid container spacing={gridSpacing}>
                            <Grid item>
                                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <nav aria-label="topics">
                                        <List>
                                            {topics &&
                                                topics.map((topic, index) => {
                                                    return (
                                                        <ListItem disablePadding key={index}>
                                                            <ListItemButton
                                                                onClick={() => {
                                                                    topicSelected(topic);
                                                                }}
                                                                selected={topic.name == getTopicName(selectedTopicId)}
                                                                sx={{ maxWidth: '250px' }}
                                                            >
                                                                <ListItemText primary={topic.name} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    );
                                                })}
                                        </List>
                                    </nav>
                                </Box>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
            </Grid>
        </SubCard>
    );
};

TopicPopover.propTypes = {
    topics: PropTypes.array,
    onSelected: PropTypes.func,
    reset: PropTypes.bool
};

export default TopicPopover;
