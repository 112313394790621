import { createContext, useState } from 'react';

// Create the context
export const SubjectContext = createContext();

// Create a provider component
export const SubjectProvider = ({ children }) => {
    const [subjects, setSubjects] = useState();

    const updateSubjects = (subs) => {
        setSubjects(subs);
    };

    return <SubjectContext.Provider value={{ subjects, updateSubjects }}>{children}</SubjectContext.Provider>;
};
