// project imports
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from 'views/ui-elements/PageHeader/index.js';

// ==============================|| ACCOUNT SETTINGS PAGE ||============================== //

const AccountSettingsPage = () => (
    <>
        <PageHeader title="Account Settings" hasBackButton={false} />
        <MainCard>Coming soon..</MainCard>
    </>
);

export default AccountSettingsPage;
