import React, { useState } from 'react';

// material-ui
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

import { gridSpacing } from 'store/constant';

// assets
import mailImg from 'assets/images/landing/widget-mail.svg';
import { useMediaQuery } from '@mui/system';
import { useTheme } from '@mui/material';
import axiosServices from 'utils/axios';
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch } from 'store';

const ContactCard = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: false,
        email: false,
        phone: false,
        message: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: false
        });
    };

    const validateForm = () => {
        const newErrors = {
            name: formData.name === '',
            email: formData.email === '',
            phone: formData.phone === '' || formData.phone.length != 10,
            message: formData.message === ''
        };
        setErrors(newErrors);

        return !Object.values(newErrors).includes(true);
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            console.log('Form Submitted', formData);
            try {
                const input = {
                    name: formData.name,
                    email: formData.email,
                    phoneNumber: formData.phone,
                    message: formData.message
                };
                const response = await axiosServices.post('/contact-us', input);
                if (response.status == 200) {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Your message was sent successfully. We will reply soon.',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                }
            } catch (error) {
                console.log(error);
            } finally {
                console.log('close loader. job done');
            }
        } else {
            console.log('Validation Failed');
        }
    };

    return (
        <Container>
            <Grid container justifyContent="center" spacing={gridSpacing}>
                <Grid item sm={10} md={7} sx={{ mt: { md: 12.5, xs: 2.5 }, mb: { md: 12.5, xs: 2.5 } }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h1"
                                color="white"
                                sx={{
                                    fontSize: { xs: '1.8125rem', md: '3.5rem' },
                                    fontWeight: 900,
                                    lineHeight: 1.4,
                                    mt: { xs: 10, md: 0 }
                                }}
                            >
                                Contact our experts
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: 400, lineHeight: 1.4, my: { xs: 'auto', md: 0 }, mx: { xs: 'auto', md: 12.5 } }}
                                color="white"
                            >
                                Fill in the form with your query and we will get in touch with you soon.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ position: 'relative', display: { xs: 'none', lg: 'block' } }}>
                    <img
                        src={mailImg}
                        alt="Berry"
                        style={{
                            marginBottom: -0.625,
                            position: 'absolute',
                            bottom: -90,
                            right: '0',
                            width: 400,
                            maxWidth: '100%',
                            animation: '5s wings ease-in-out infinite'
                        }}
                    />
                </Grid>
                <Grid item xs={matchDownMd ? 12 : 6} sx={{ mb: -37.5 }}>
                    <Card sx={{ mb: 6.25 }} elevation={4}>
                        <CardContent sx={{ p: 4 }}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth error={errors.name}>
                                        <InputLabel>Name</InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            label="Name"
                                            placeholder="Enter Your Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                        {errors.name && <Typography color="error">Name is required</Typography>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth error={errors.email}>
                                        <InputLabel>Email Address</InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            label="Email Address"
                                            placeholder="Enter Your Email Address"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && <Typography color="error">Email is required</Typography>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth error={errors.phone}>
                                        <InputLabel>Phone Number</InputLabel>
                                        <OutlinedInput
                                            type="number"
                                            label="Phone Number"
                                            placeholder="Enter Your Phone Number"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                        {errors.phone && <Typography color="error">A valid Phone number is required</Typography>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth error={errors.message}>
                                        <TextField
                                            id="outlined-multiline-static1"
                                            placeholder="Message"
                                            multiline
                                            fullWidth
                                            rows={4}
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            defaultValue=""
                                        />
                                        {errors.message && <Typography color="error">Message is required</Typography>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={gridSpacing} display="flex" justifyContent={'center'}>
                                        <Grid item>
                                            <AnimateButton>
                                                <Button variant="contained" color="secondary" onClick={handleSubmit}>
                                                    Submit
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ContactCard;
