// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, useMediaQuery, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons-react';
import useAuth from 'hooks/useAuth';
// import COLOURS from 'utils/colors';
import { useContext, useEffect, useState } from 'react';
import { ModeContext } from 'contexts/ModeContext';
import { useLocation } from 'react-router-dom';
import { EditNote, Quiz } from '@mui/icons-material';
import { SubjectContext } from 'contexts/SubjectContext';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();
    const { mode, toggleMode } = useContext(ModeContext);
    const location = useLocation();
    const [showModeChangeButtons, setShowModeChangeButtons] = useState(false);
    const { subjects } = useContext(SubjectContext);

    useEffect(() => {
        if (location.pathname === '/dashboard' && subjects?.length > 0) {
            setShowModeChangeButtons(true);
        } else {
            setShowModeChangeButtons(false);
        }
    }, [location, subjects]);

    const handleModeSwitch = (event, newMode) => {
        if (newMode !== null) {
            toggleMode(newMode);
        }
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>

                {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                ) : null}
            </Box>
            <Box marginLeft={2}>
                <Typography variant="h3">Welcome, {user?.name}!</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {showModeChangeButtons && (
                <Box sx={{ display: 'flex' }} alignItems={'center'}>
                    <Typography variant="h3">Mode:&nbsp;</Typography>
                    <ToggleButtonGroup value={mode} exclusive onChange={handleModeSwitch} aria-label="Mode" color="primary">
                        <ToggleButton value="practice">
                            <EditNote sx={{ mr: 0.5 }} />
                            {matchDownMd ? 'P' : 'Learn & Practice'}
                        </ToggleButton>
                        <ToggleButton value="assessment">
                            <Quiz sx={{ mr: 0.5 }} />
                            {matchDownMd ? 'A' : 'Assessment'}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )}
        </>
    );
};

export default Header;
