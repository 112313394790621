import { Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';
import useAuth from 'hooks/useAuth';
import axiosServices from 'utils/axios';
import ReportDialog from 'views/ui-elements/ReportDialog';
import MuiTooltip from '@mui/material/Tooltip';
import { DescriptionOutlined } from '@mui/icons-material';
import noNotesImage from 'assets/images/app-images/no-notes.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';

const DocumentsCard = ({ selectedElement, chapterMedia }) => {
    const [media, setMedia] = useState();
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const dispatch = useDispatch();
    const { user } = useAuth();

    useEffect(() => {
        // selectedElement && selectedElement.Media && setMedia(selectedElement.Media.filter((element) => element.type == 'DOCUMENT'));
        chapterMedia && chapterMedia.length && setMedia(chapterMedia.filter((element) => element.type == 'DOCUMENT'));
    }, [selectedElement]);

    // const handleReportButtonClick = () => {
    //     setIsReportModalOpen(true);
    // };

    const handleReportSubmit = async (reason) => {
        try {
            let input = {
                studentId: user.id,
                reportedItemId: selectedElement?.id, // Replace with the actual reported item ID
                reportedItemType: 'VIDEO', // Replace with the actual reported item type
                reason: reason
            };
            const response = await axiosServices.post('/report', input);
            if (response.status == 200) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Document was reported successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'There was a problem while reporting the document. Please try later!',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    return (
        <div>
            {selectedElement && media?.length ? (
                <Grid container alignItems="center" spacing={2} overflow={'auto'}>
                    <Grid item xs={12}>
                        <PerfectScrollbar style={{ height: 397 }}>
                            {media.map((document, index) => (
                                <Grid item xs={12} key={index} style={{ borderBottom: '1px solid #eee' }} py={1}>
                                    <Grid container item direction="row" alignItems="center">
                                        <Grid item xs={12}>
                                            <Grid container direction="row" display={'flex'} alignItems="center">
                                                <Grid item xs={1} display={'flex'} alignItems="center">
                                                    <DescriptionOutlined color="primary" />
                                                </Grid>
                                                <Grid item xs={8} display={'flex'} alignItems="center">
                                                    <Typography pl={0.5} variant="h5" color="inherit">
                                                        {document.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} display={'flex'} justifyContent={'end'}>
                                                    <MuiTooltip title="Read Notes">
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() => {
                                                                window.open(document.url, '_blank');
                                                            }}
                                                        >
                                                            Read
                                                        </Button>
                                                    </MuiTooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </PerfectScrollbar>
                    </Grid>
                </Grid>
            ) : (
                <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2}>
                    <Grid item>
                        <img src={noNotesImage} alt="Not found" width={'150px'} />
                    </Grid>
                </Grid>
            )}
            <ReportDialog open={isReportModalOpen} onClose={() => setIsReportModalOpen(false)} onSubmit={handleReportSubmit} />
        </div>
    );
};

DocumentsCard.propTypes = {
    selectedElement: PropTypes.object,
    chapterMedia: PropTypes.array
};

export default DocumentsCard;
