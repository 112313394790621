import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

export default function AlertDialog({ isOpen, onClose, onAgree, title, content }) {
    if (!isOpen) {
        return null;
    }

    const handleCancel = () => {
        onClose();
    };

    const handleAgree = () => {
        onAgree();
        onClose();
    };

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="error" size="small">
                        Disagree
                    </Button>
                    <Button onClick={handleAgree} color="success" size="small" variant="contained">
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

AlertDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onAgree: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string
};
