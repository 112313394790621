import React, { useEffect, useState } from 'react';

// material-ui
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// assets
import { Facebook, Google, Info, Instagram, LinkedIn, ThumbsUpDown } from '@mui/icons-material';
import useAuth from 'hooks/useAuth';

// =============================|| UI SPEEDDIAL - SIMPLE ||============================= //

export default function SimpleSpeedDials() {
    const [open, setOpen] = React.useState(false);

    const [actions, setActions] = useState([
        { icon: <Google sx={{ color: 'grey700' }} />, name: 'Google', url: 'https://g.page/r/CYg4JZbmYbEVEAE/review' },
        { icon: <Instagram sx={{ color: 'grey700' }} />, name: 'Instagram', url: 'https://www.instagram.com/find.inbox/' },
        { icon: <LinkedIn sx={{ color: 'grey700' }} />, name: 'Linked In', url: 'https://www.linkedin.com/company/findinbox/' },
        { icon: <Facebook sx={{ color: 'grey700' }} />, name: 'Facebook', url: 'https://www.facebook.com/findinbox/' }
    ]);
    const { user } = useAuth();

    useEffect(() => {
        if (user?.Partner && user?.Partner?.resourceUrl) {
            const infoAction = {
                icon: <Info sx={{ color: 'grey700' }} />,
                name: 'Resources',
                url: user?.Partner?.resourceUrl
            };
            const newActions = [...actions, infoAction];
            setActions(newActions);
        }
    }, [user]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <SpeedDial
            sx={{
                position: 'fixed',
                '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': { bottom: 10, left: 10 },
                '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': { top: 30, right: 50 }
            }}
            ariaLabel="Rate Us"
            icon={<ThumbsUpDown />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={'up'}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => window.open(action.url, '_blank')}
                />
            ))}
        </SpeedDial>
    );
}
