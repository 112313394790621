// project imports
import { Grid } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import axiosServices from 'utils/axios';
import ChapterPopover from 'views/ui-elements/ChapterPopover';
import PageHeader from 'views/ui-elements/PageHeader/index.js';
import AssessmentCard from './assessment-card';

// ==============================|| ACCOUNT SETTINGS PAGE ||============================== //

const AssessmentsListPage = () => {
    const { state } = useLocation();
    const { user } = useAuth();
    const { chapters, subject } = state?.chapters && state?.subject ? state : { chapters: [], subject: [] };
    const [selectedChapter, setSelectedChapter] = useState();
    const [assessments, setAssessments] = useState();

    useEffect(() => {
        selectedChapter && getAssessmentsForChapter();
    }, [selectedChapter]);

    const getAssessmentsForChapter = async () => {
        try {
            const response = await axiosServices.get(`/assessments?chapterId=${selectedChapter.id}&studentId=${user.id}`);
            setAssessments(response.data);
        } catch {
            console.log('error');
        } finally {
            console.log('close loader. job done');
        }
    };

    const onChapterSelected = (newChapter) => {
        setSelectedChapter(newChapter);
    };

    return (
        <>
            <PageHeader title={`Assessments - ${subject?.name}`} hasBackButton={true} />
            <Grid container display={'flex'} alignItems="center" justifyContent="start" spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ChapterPopover chapters={chapters} onChapterSelected={onChapterSelected} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {assessments?.length > 0 ? (
                <>
                    <Grid container spacing={gridSpacing} mt={-1}>
                        {assessments?.map((assessment) => {
                            return (
                                <Grid key={assessment.id} item xs={12} md={3} sm={12}>
                                    <AssessmentCard assessment={assessment} chapter={selectedChapter} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container spacing={gridSpacing} mt={-1}>
                        <Grid item>No assessments found</Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default AssessmentsListPage;
