import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Create the context
export const ModeContext = createContext();

// Create a provider component
export const ModeProvider = ({ children }) => {
    // there are 2 modes: practice and assessment.
    // default mode will be practice
    const navigate = useNavigate();
    const [mode, setMode] = useState('practice');

    useEffect(() => {
        const storedMode = localStorage.getItem('selectedMode');

        // If 'selectedMode' doesn't exist, is null, or is an empty string, default to 'practice'
        if (!storedMode || storedMode === 'null' || storedMode === '') {
            localStorage.setItem('selectedMode', 'practice');
            setMode('practice');
        } else {
            setMode(storedMode);
        }
    }, []);

    const toggleMode = (newMode) => {
        localStorage.setItem('selectedMode', newMode);
        setMode(newMode);
        navigate('/dashboard');
    };

    return <ModeContext.Provider value={{ mode, toggleMode }}>{children}</ModeContext.Provider>;
};
