// project imports
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Box, Divider } from '@mui/material';

import QuizIcon from '@mui/icons-material/Quiz';
import InsightsIcon from '@mui/icons-material/Insights';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axios';
import { Assessment, AutoAwesome, LooksOne, Summarize } from '@mui/icons-material';

const SubjectDetails = ({ subjectId }) => {
    const [subjectDetails, setSubjectDetails] = useState();

    const getSubjectDetails = async () => {
        try {
            const response = await axiosServices.get(`/subjects/${subjectId}/details`);
            if (response.data) {
                setSubjectDetails(response.data);
            } else {
                setSubjectDetails([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    useEffect(() => {
        getSubjectDetails();
    }, [subjectId]);

    const features = [
        { icon: <LooksOne sx={{ fontSize: 35 }} />, title: '1 Year Validity' },
        { icon: <QuizIcon sx={{ fontSize: 35 }} />, title: 'Skill Identifying Questions' },
        { icon: <AutoAwesome sx={{ fontSize: 35 }} />, title: 'AI Explanations' },
        { icon: <InsightsIcon sx={{ fontSize: 35 }} />, title: 'Personalized Analysis' },
        { icon: <Assessment sx={{ fontSize: 35 }} />, title: 'Skill Card' },
        { icon: <Summarize sx={{ fontSize: 35 }} />, title: 'Detailed Score Card' }
    ];
    return (
        <>
            <Box sx={{ padding: 1 }}>
                <Grid container spacing={1} justifyContent="center">
                    {features.map((feature, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    padding: 1
                                }}
                            >
                                <Box sx={{ color: 'primary.main' }}>{feature.icon}</Box>
                                <Typography variant="h5" sx={{ marginTop: 1 }}>
                                    {feature.title}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Divider sx={{ my: 2 }} />
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: 'center' }}>Chapter</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Concepts</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Skill Identifying Questions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subjectDetails &&
                            subjectDetails.length > 0 &&
                            subjectDetails.map((details, index) => {
                                return (
                                    <TableRow key={index} hover tabIndex={-1}>
                                        <TableCell sx={{ textAlign: 'center' }}>{details.chapterName}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{details.topics}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{details.questionsCount}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default SubjectDetails;
