import { Flag } from '@mui/icons-material';
import { Button, CardMedia, Grid, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import ReportDialog from 'views/ui-elements/ReportDialog';

const VideoContentPage = ({ selectedElement, video, showReportButton = true }) => {
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const { user } = useAuth();
    const dispatch = useDispatch();

    const handleReportButtonClick = () => {
        setIsReportModalOpen(true);
    };

    const handleReportSubmit = async (reason) => {
        try {
            let input = {
                studentId: user.id,
                reportedItemId: selectedElement?.id, // Replace with the actual reported item ID
                reportedItemType: 'VIDEO', // Replace with the actual reported item type
                reason: reason
            };
            const response = await axiosServices.post('/report', input);
            if (response.status == 200) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Video was reported successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'There was a problem while reporting the video. Please try later!',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    return (
        <>
            <Grid container direction={'column'} spacing={2}>
                <Grid item>
                    <CardMedia sx={{ width: '100%', height: 450, borderRadius: 2 }} component="iframe" src={video.url} title="image" />
                </Grid>
                {showReportButton && (
                    <Grid item display={'flex'} justifyContent={'center'}>
                        <Tooltip title="Report Video">
                            <Button onClick={() => handleReportButtonClick()} variant="contained" endIcon={<Flag />} color="warning">
                                Report Video
                            </Button>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            <ReportDialog open={isReportModalOpen} onClose={() => setIsReportModalOpen(false)} onSubmit={handleReportSubmit} />
        </>
    );
};

VideoContentPage.propTypes = {
    selectedElement: PropTypes.object,
    video: PropTypes.object,
    showReportButton: PropTypes.bool
};

export default VideoContentPage;
