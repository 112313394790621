import { Typography, useMediaQuery, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Chip from 'ui-component/extended/Chip';
import axiosServices from 'utils/axios';
import Assessment from 'views/ui-elements/Assessment';
import PageHeader from 'views/ui-elements/PageHeader';

const PracticeQuestionsPage = () => {
    const { state } = useLocation();
    const { chapter, topic, skill, mode } = state;
    // there are 2 modes
    // 1. practice mode: here you will answer the unanswered questions in the topic or skill
    // 2. result mode: here you will see the result of the answered questions with solution
    const [selectedChapter] = useState(chapter);
    const [selectedTopic] = useState(topic);
    const [selectedSkill] = useState(skill);
    const [questions, setQuestions] = useState();
    const [answers, setAnswers] = useState();
    const { user } = useAuth();
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    const randomizeQuestions = (ques) => {
        const newArray = [...ques];
        for (let i = newArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = newArray[i];
            newArray[i] = newArray[j];
            newArray[j] = temp;
        }
        return newArray;
    };

    const getAnswersForQuestions = async (ques) => {
        const input = {
            studentId: user.id,
            questionIds: ques.map((question) => {
                return question.id;
            })
        };
        try {
            const response = await axiosServices.post(`/answers/practice`, input);
            if (response.data && response.data.length) {
                setAnswers(response.data);
                const answeredQuestionIds = response.data.map((answer) => answer.questionId);
                const unansweredQuestions = ques.filter((question) => !answeredQuestionIds.includes(question.id));
                const answeredQuestions = ques.filter((question) => answeredQuestionIds.includes(question.id));
                let randomQuestions;
                if (isPracticeMode()) {
                    randomQuestions = randomizeQuestions(unansweredQuestions);
                } else {
                    // randomQuestions = randomizeQuestions(answeredQuestions);
                    randomQuestions = JSON.parse(JSON.stringify(answeredQuestions)); //do not randomize view answers questions
                }
                setQuestions(randomQuestions);
            } else {
                if (isPracticeMode()) {
                    setQuestions(randomizeQuestions(ques));
                }
            }
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            console.log('close loader. job done');
        }
    };

    const getQuestionsForPractice = async () => {
        const input = {
            chapterId: selectedChapter.id,
            topicId: selectedTopic?.id,
            tagId: selectedSkill?.id,
            classification: 'PRACTICE' //this is question classification
        };
        /* if a user belongs to a partner, then show only verified questions
           if a user does not belong to a partner (patnerId is null), 
           then this user is a demo user and should show all questions including unverified ones */
        if (user.partnerId) {
            input.verifiedOnly = true;
        }
        /* end */
        try {
            const response = await axiosServices.post(`/questions/practice`, input);
            response.data?.length && getAnswersForQuestions(response.data);
        } catch (error) {
            console.log(error);
            console.log('error');
        } finally {
            console.log('close loader. job done');
        }
    };

    const isPracticeMode = () => {
        return mode == 'practice';
    };

    useEffect(() => {
        getQuestionsForPractice();
    }, [selectedTopic, selectedSkill]);

    return (
        <>
            <PageHeader
                title={chapter.name}
                hasBackButton={true}
                actionComponent={
                    <>
                        {!matchDownSm && (
                            <>
                                <Typography variant="h4">{topic ? 'Topic' : 'Skill'}&nbsp;Selected&nbsp;:&nbsp;</Typography>
                                <Chip label={topic ? topic.name : skill.name} />
                            </>
                        )}
                    </>
                }
            />
            <Assessment questions={questions} answers={answers} mode={mode} />
        </>
    );
};

export default PracticeQuestionsPage;
