import PropTypes from 'prop-types';

// material-ui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

function SimpleDialog({ onClose, content, open, title, maxWidth = 'md' }) {
    const handleClose = (event, reason) => {
        onClose({ event, reason });
    };

    return (
        <Dialog disableEscapeKeyDown onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth maxWidth={maxWidth}>
            {open && (
                <>
                    <DialogTitle id="simple-dialog-title">
                        {title}
                        <IconButton onClick={handleClose} style={{ float: 'right' }}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>{content}</DialogContent>
                </>
            )}
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    content: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    maxWidth: PropTypes.string
};

export default SimpleDialog;
