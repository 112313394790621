const STRINGS = {
    EXIT_ASSESSMENT_TITLE: 'Are you sure you want to exit?',
    EXIT_ASSESSMENT_CONTENT:
        'If you exit this assessment, it will be marked as incomplete and will not be considered for calculating performances. The changes will be stored and you can resume the assessment from the assessments list page later. Are you sure you want to exit?',
    ASSESSMENT_INSTRUCTIONS_TITLE: 'Instructions',
    ASSESSMENT_INSTRUCTIONS_CONTENT: `<div>
            <ol>
                <li>
                    <strong>One-time attempt:</strong> This is a single attempt practice quiz. Once submitted, you cannot retake or change
                    your answers.
                </li>
                <li>
                    <strong>Time limit:</strong> Each question has a specific time limit. Answer within the allotted time.
                </li>
                <li>
                    <strong>Moving to the next question:</strong> Click &apos;Next&apos; to proceed to the next question and automatically
                    save your answer.
                </li>
                <li>
                    <strong>Resuming the quiz:</strong> If you go back without answering a question, you can answer it later by resuming the
                    quiz from where you left off.
                </li>
                <li>
                    <strong>Unanswered questions:</strong> If you click &apos;Next&apos; without answering, the question will be marked as
                    unanswered and cannot be answered later.
                </li>
                <li>
                    <strong>Reporting questions:</strong> If you believe a question or its options are incorrect, you can report the
                    question.
                </li>
                <li>
                    <strong>Final submission:</strong> Review your answers carefully before submitting the quiz, as no changes can be made
                    afterward.
                </li>
            </ol>
            <br />
            <div style="text-align: center;"><strong>Good luck with your quiz!</strong></div>
        </div>`
};

export default STRINGS;
