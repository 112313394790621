import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Alert } from '@mui/material';

const ReportDialog = ({ open, onClose, onSubmit }) => {
    const [reason, setReason] = useState('');
    const [error, setError] = useState('');

    const handleClose = () => {
        setReason('');
        setError('');
        onClose();
    };

    const handleSubmit = () => {
        if (!reason.trim()) {
            setError('Reason cannot be empty.');
            return;
        }

        onSubmit(reason);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="report-modal-title" aria-describedby="report-modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Typography id="report-modal-title" variant="h3" component="h2">
                    Report
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                <TextField
                    id="report-modal-description"
                    label="Why are you reporting?"
                    multiline
                    rows={4}
                    value={reason}
                    onChange={(e) => {
                        setReason(e.target.value);
                        setError('');
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={!!error}
                    helperText={error}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ ml: 2 }}>
                        Send Report
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ReportDialog;
